import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getPlayer,
  getPlayerAchievements,
  getPlayerPrizes,
  getTeam,
} from "../../services/gamelayer";
import { Achievement } from "../../components/Elements/Achievement";
import Prize from "../../components/Elements/Prize/Prize";
import { useAuth0 } from "@auth0/auth0-react";
import "./styles.scss";

export const PlayerProfile = () => {
  const { playerId } = useParams();
  const { user } = useAuth0();
  const glAccount = process.env.REACT_APP_ACCOUNT;
  const [player, setPlayer] = useState(null);
  const [achievements, setAchievements] = useState([]);
  const [prizes, setPrizes] = useState([]);
  const [team, setTeam] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const fetchTeamData = async (id) => {
        try {
          const teamData = await getTeam(glAccount, id);
          return teamData;
        } catch (error) {
          return null;
        }
      }

      try {

        const [playerData, achievementsData, prizesData] = await Promise.all([
          getPlayer(glAccount, playerId).then((player) => {
            if (player.team) {
              fetchTeamData(player.team).then((team) => {
                setTeam(team);
              });
            }
            return player;
          }),
          getPlayerAchievements(glAccount, playerId),
          getPlayerPrizes(glAccount, playerId),
        ]);
  
        setPlayer(playerData);
        setAchievements(achievementsData.achievements.completed);
  
        // Remove duplicate prizes based on id field
        const uniquePrizesMap = new Map();
        prizesData.prizes.forEach((prize) => {
          uniquePrizesMap.set(prize.id, prize);
        });
  
        const uniquePrizes = Array.from(uniquePrizesMap.values());
        setPrizes(uniquePrizes);
      } catch (error) {
        console.error("Error fetching player data:", error);
        toast.error(
          `Failed to retrieve player data. Please try again. Error: ${error.message}`,
          {
            toastId: "player-data-fetch-error",
          }
        );
      }
    };
  
    fetchData();
  }, [glAccount, playerId]);
  
  if (!player) {
    return null;
  }

  const isUserPlayer = user.email.toLowerCase() === playerId.toLowerCase();

  return (
    <div className="page-content player-page">
      <div key={player.player} className="player-page-top">
        <div className="player-page-avatar">
          <img src={player.imgUrl} alt={player.name} className="player-image" />
        </div>
        <div className="player-info">
          <h2>{player.name}</h2>
          {isUserPlayer && (
            <Link to={`${window.location.href}/edit`} className="button">
              Edit Profile
            </Link>
          )}
        </div>
        <div className="player-page-team-info">
          <p>Team</p>
          <h3>{team?.team?.name}</h3>
        </div>
      </div>
      <div className="player-page-stats">
        <div>
          <h4>{player.level?.name ? player.level.name : "not ranked yet"}</h4>
          <p>Level</p>
        </div>
        <div>
          <h4>{player.points}</h4>
          <p>Points</p>
        </div>
        <div>
          <h4>{player.credits}</h4>
          <p>Credits</p>
        </div>
      </div>
      {achievements.length > 0 && (
        <>
          <h4 className="player-page-achievements-title">Achievements</h4>
          <div className="player-page-achievements-list">
            {achievements.map((achievement) => (
              <div key={achievement.id} className="element-container">
                <Achievement
                  imageUrl={achievement.imgUrl}
                  title={achievement.name}
                />
              </div>
            ))}
          </div>
        </>
      )}
      {prizes.length > 0 && (
        <>
          <h4 className="player-page-prizes-title">Claimed prizes</h4>
          <div className="shop-items-container">
            {prizes.map((prize, index) => (
              <Prize
                index={index}
                displayMode="list-item"
                showButton={false}
                key={prize.id}
                shopItem={prize}
                isAvailable={true}
                isLink={false}
                />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
