import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import "react-toastify/dist/ReactToastify.css";
import { getLeaderBoard, getTeam, getPlayer } from "../../../services/gamelayer.js";
import { Avatar } from "../Avatar/Avatar";
import "./styles.scss";

export const Leaderboard = ({
  displaySlim = false,
  boardId = "leaderboard-one",
}) => {
  const account = process.env.REACT_APP_ACCOUNT;
  const [leaderboard, setLeaderboard] = useState([]);
  const [leaderBoardTitle, setLeaderBoardTitle] = useState("");
  const [scores, setScores] = useState([]);
  const [teamScores, setTeamScores] = useState([]);
  const [boardType, setBoardType] = useState("teams");
  const [teamId, setTeamId] = useState(null);
  const [teamName, setTeamName] = useState("");

  useEffect(() => {
    const fetchTeamData = async (id) => {
      if (id !== null) {
        try {
          const teamData = await getTeam(account, id);
          return teamData;
        } catch (error) {
          console.error("Error fetching team data:", error);
          return null;
        }
      }
    };

    const fetchLeaderboard = async () => {
      try {
        const leaderboardData = await getLeaderBoard(
          account,
          boardId,
          ""
        );
        setLeaderBoardTitle(leaderboardData?.leaderboard?.name);

        const processedLeaderboard = await Promise.all(
          leaderboardData.scores.data.filter((score) => score !== null).map(async (score) => {
            const teamData = await fetchTeamData(score.team);
            return teamData ? { ...score, teamData } : null;
          })
        );

        setLeaderboard(processedLeaderboard.filter((item) => item !== null));
      } catch (error) {
        console.error("Error fetching leaderboards:", error);
        toast.error(
          `Failed to retrieve leaderboards. Please try again. Error: ${error.message}`,
          {
            toastId: "achievements-fetch-error",
          }
        );
      }
    };

    fetchLeaderboard();

  }, [boardId]);

  useEffect(() => {
    const fetchPlayerData = async (playerId) => {
      try {
        const playerData = await getPlayer(account, playerId);
        return playerData;
      } catch (error) {
        console.error("Error fetching player data:", error);
        return null;
      }
    };

    const fetchTeamLeaderboard = async () => {
      if (teamId === null) {
        return;
      }
      try {
        const leaderboardData = await getLeaderBoard(
          account,
          "leaderboard-two",
          teamId
        );
        setLeaderBoardTitle(leaderboardData?.leaderboard?.name);

        const processedLeaderboard = await Promise.all(
          leaderboardData.scores.data.filter((score) => score !== null).map(async (score) => {
            const playerData = await fetchPlayerData(score.player);
            return playerData ? { ...score, playerData } : null;
          })
        );
        setTeamScores(processedLeaderboard);
      } catch (error) {
        console.error("Error fetching teams leaderboard:", error);
        toast.error(
          `Failed to retrieve teams leaderboards. Please try again. Error: ${error.message}`,
          {
            toastId: "achievements-fetch-error",
          }
        );
      }
    };

    fetchTeamLeaderboard();

  }, [boardId, teamId]);

  useEffect(() => {
    if (displaySlim === true) {
      const scores = leaderboard.map((score, index) => {
        if (!score || !score.teamData) {
          return null;
        }
        return (
          <div key={index} className="leader-board-item">
            <Avatar imageUrl={score.teamData?.team?.imgUrl} alt={score.name} />
            <h6 className="leaderboard-score__name board-item">{score.name}</h6>
            <p className="leaderboard-score__score board-item">Score: {score.scores}</p>
          </div>
        );
      });
      setScores(scores);
      return;
    }

    if (boardType === "players") {
      const scores = teamScores.map((score, index) => {
        return (
          <motion.div
            key={index}
            className="leader-board-row"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: index * 0.7 }}
            onClick={() => { window.location.href = `/players/${score.player}` }}
          >
            <div className="leaderboard-score__position board-item">
              <span>{score.rank}</span>
            </div>
            <div className="leaderboard-score__avatar board-item">
              <Avatar imageUrl={score.playerData?.imgUrl} alt={score.name} />
            </div>
            <div className="leaderboard-score__name board-item">{score.name}</div>
            <div className="leaderboard-score__level board-item">
              <img src={score?.playerData?.level?.imgUrl}/>
              <span>{score?.playerData?.level?.name}</span>
            </div>
            <div className="leaderboard-score__score board-item">{score.scores}</div>
          </motion.div>
        );
      });
      setScores(scores);
    } else {
      const scores = leaderboard.map((score, index) => {
        return (
          <motion.div
            key={index}
            className="leader-board-row"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: index * 0.7 }}
            onClick={() => {
              setBoardType("players");
              setTeamId(score.teamData?.team?.id);
              setTeamName(score.name);
            }}
          >
            <div className="leaderboard-score__position board-item">
              <span>{score.rank}</span>
            </div>
            <div className="leaderboard-score__logo board-item">
              <img src={score.teamData?.team?.imgUrl} alt={score.name} />
            </div>
            <div className="leaderboard-score__name board-item">{score.name}</div>
            <div className="leaderboard-score__members board-item">{score.teamData?.players?.length}</div>
            <div className="leaderboard-score__score board-item">{score.scores}</div>
          </motion.div>
        );
      });
      setScores(scores);
    }
  }, [leaderboard, teamScores, leaderBoardTitle, boardType, teamId, displaySlim]);

  if (displaySlim) {
    return (
      <motion.div
        className="leaderboards-wrapper"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: 0.5 }}
      >
        <div className="leaderboard slim">
          {scores}
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div
      className="leaderboards-wrapper"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: .5 }}
    >
      <div className="leaderboard table">
        {boardType === "players" ? (
          <button
            className="back-link"
            onClick={() => {setBoardType("teams"); setTeamScores([]);}}
          >
            <img src="/images/assets/arrow_left.svg" alt="back"></img>
            Back to Teams
          </button>
        ) : (
          <a href="/" className="back-link">
            <img src="/images/assets/arrow_left.svg" alt="back"></img>
            Back to Dashboard
          </a>
        )
        }
        <h2>{boardType === "teams" ? leaderBoardTitle : teamName }</h2>
        <div className="leader-board-row leaderboard-header">
          <div className="leaderboard-header__position">Ranking</div>
          <div className="leaderboard-header__logo">
            { boardType === "teams" ? <span>Logo</span> : <span>Avatar</span> }
          </div>
          <div className="leaderboard-header__name">Name</div>
          <div className="leaderboard-header__members">
          {boardType === "teams" ? <span>No. Members</span> : <span>Level</span> }
          </div>
          <div className="leaderboard-header__score">Score</div>
        </div>
        {scores}
      </div>
    </motion.div>
  );

};
