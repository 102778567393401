import React, { useEffect, useState } from "react";
import "./styles.scss";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { Tag } from "../Tag";

const Prize = ({
  index = 1,
  displayMode = "full",
  showButton = false,
  shopItem,
  claimPrize,
  isAvailable = false,
  isLink = true
}) => {
  const [prizePageUri, setPrizePageUri] = useState();
  const [content, setContent] = useState();
  const dateOptions = {
    weekday: 'long', // 'Monday'
    year: 'numeric', // '2024'
    month: 'long', // 'September'
    day: 'numeric', // '27'
  };
  useEffect(() => {
    const prepareData = () => {
      setPrizePageUri("/prizes/" + shopItem.id);
    }
    
    prepareData();
  }, []);
  
  useEffect(() => {
    switch (displayMode) {
      default:
        setContent(
            <div className="shop-item-container">
              <NavLink to={isLink ? prizePageUri : window.location.href}>
                <div className="shop-item--media">
                  <img
                    src={shopItem.imgUrl}
                    alt={shopItem.name}
                    className="shop-item-image"
                  />
                </div>
                <h2>{shopItem.name}</h2>
                  <div className="shop-item-info">
                    <p>
                      {shopItem.description === ""
                        ? "Description not set"
                        : shopItem.description}
                    </p>
                    { shopItem.stock && (
                      <p>Redeemed: {shopItem.stock.redeemed}</p>
                    )}
                  </div>
                <div className="shop-item-info-bottom">
                  {showButton ? (
                    <button
                      className="shop-item-redeem-btn"
                      onClick={() => claimPrize(shopItem.id)}
                    >
                      <span>{shopItem.credits} Points</span>
                    </button>
                  ) : (
                     shopItem.credits && (
                      <Tag text={shopItem.credits + " Points"} inverted={true}/>
                    )
                  )}
                  { shopItem.stock && (
                    <p>Available: {shopItem.stock.available < 10000 ? shopItem.stock.available : 'plenty'}</p>
                  )}
                </div>
              </NavLink>
            </div>
        );
        break;
      case "slim":
        setContent(
            <div className="shop-item-container">
              <NavLink to={isLink ? prizePageUri : window.location.href}>
                <div className="shop-item--media">
                  <img
                    src={shopItem.imgUrl}
                    alt={shopItem.name}
                    className="shop-item-image"
                  />
                </div>
                <h2>{shopItem.name}</h2>
                  <div className="shop-item-info">
                    <p>
                      {shopItem.description === ""
                        ? " "
                        : shopItem.description.substring(0, 50)}...
                    </p>
                  </div>
                <div className="shop-item-info-bottom">
                  {showButton ? (
                    <button
                      className="shop-item-redeem-btn"
                      onClick={() => claimPrize(shopItem.id)}
                    >
                      <span>{shopItem.credits} Points</span>
                    </button>
                  ) : (
                     shopItem.credits && (
                      <Tag text={shopItem.credits + " Points"} inverted={true}/>
                    )
                  )}
                  { shopItem.stock && (
                    <p>Available: {shopItem.stock.available < 10000 ? shopItem.stock.available : 'plenty'}</p>
                  )}
                </div>
              </NavLink>
            </div>
        );
        break;
      case "list-item":
        const date = new Date(shopItem?.actions?.completedOn);
        const formattedDate = date.toLocaleDateString('en-GB', dateOptions);
        setContent(
            <div
              className="shop-item-container list-item"
              onClick={() => window.location.href = prizePageUri}
            >
              <h4>{shopItem.name}</h4>
              <div>
                {formattedDate}
              </div>
              <div className="">
                {shopItem.credits + " Points"}
              </div>
              <div className="more-link">
                <span>More <img src="/images/assets/icons/arrow.svg"/></span>
              </div>
            </div>
        );
        break;
    }
  }, [shopItem, displayMode]);


  return (shopItem.isAvailable || isAvailable) && (
    <motion.div
      className="shop-item-wrapper"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5, delay: index * 0.2 }}
    >
      {content}
    </motion.div>
  )
};

export default Prize;
