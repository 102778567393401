import axios from "axios";

const DEBUG = false;
// Create a custom Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "api-key": process.env.REACT_APP_API_KEY,
  },
});

const getEvents = async (account) => {
  if (DEBUG) {
    console.log("Events requested");
  }
  const response = await axiosInstance.get(`/events?account=${account}`);
  return response.data;
};

const getEvent = async (account, id) => {
  if (DEBUG) {
    console.log("Event requested");
  }
  const response = await axiosInstance.get(`/events/${id}?account=${account}`);
  return response.data;
};

const getMissions = async (account) => {
  if (DEBUG) {
    console.log("Missions requested");
  }
  const response = await axiosInstance.get(`/missions?account=${account}`);
  return response.data;
};

const getMission = async (account, id) => {
  if (DEBUG) {
    console.log("Mission requested");
  }
  const response = await axiosInstance.get(
    `/missions/${id}?account=${account}&availability=true&sort=priority`
  );
  return response.data;
};

const getAchievements = async (account) => {
  if (DEBUG) {
    console.log("Achievements requested");
  }
  const response = await axiosInstance.get(`/achievements?account=${account}`);
  return response.data;
};

const getPrize = async (account, id) => {
  if (DEBUG) {
    console.log("Prize requested");
  }
  const response = await axiosInstance.get(`/prizes/${id}?account=${account}`);
  return response.data;
};

const getPrizes = async (account) => {
  if (DEBUG) {
    console.log("Prizes requested");
  }
  const response = await axiosInstance.get(`/prizes?account=${account}`);
  return response.data;
};

const getPlayers = async (account, sort = "points") => {
  if (DEBUG) {
    console.log("Players requested");
  }
  let query = `/players?account=${account}`;
  switch (sort) {
    case "points":
      query = `/players?account=${account}&sort=points`;
      break;
    case "name":
      query = `/players?account=${account}&sort=name&order=asc`;
      break;
    case "team":
      query = `/players?account=${account}&sort=team&order=desc`;
      break;
    case "level":
      query = `/players?account=${account}&sort=level`;
      break;
    default:
      query = `/players?account=${account}&sort=${sort}}`;
      break;
  }
  const response = await axiosInstance.get(query);
  return response.data;
};

const getTeams = async (account) => {
  if (DEBUG) {
    console.log("Teams requested");
  }
  const response = await axiosInstance.get(`/teams?account=${account}`);
  return response.data;
};

const getTeam = async (account, id) => {
  if (DEBUG) {
    console.log("Team requested");
  }
  const response = await axiosInstance.get(`/teams/${id}?account=${account}`);
  return response.data;
};

const getPlayer = async (account, player) => {
  if (DEBUG) {
    console.log("Player requested");
  }
  const response = await axiosInstance.get(
    `/players/${player}?account=${account}`
  );
  return response.data;
};

const getPlayerLevels = async (account, player) => {
  if (DEBUG) {
    console.log("Player levels requested");
  }
  const response = await axiosInstance.get(
    `/players/${player}/levels/?account=${account}`
  );
  return response.data;
};

const getPlayerAchievements = async (account, player) => {
  if (DEBUG) {
    console.log("Player achievements requested");
  }
  const response = await axiosInstance.get(
    `/players/${player}/achievements/?account=${account}`
  );
  return response.data;
};

const getPlayerMission = async (account, player, missionId) => {
  if (DEBUG) {
    console.log("Player missions requested");
  }
  const response = await axiosInstance.get(
    `/players/${player}/missions/${missionId}/?account=${account}&availability=true`
  );
  return response.data;
};

const getPlayerMissions = async (account, player) => {
  if (DEBUG) {
    console.log("Player missions requested");
  }
  const response = await axiosInstance.get(
    `/players/${player}/missions/?account=${account}&availability=true`
  );
  return response.data;
};

const getPlayerPrizes = async (account, player) => {
  if (DEBUG) {
    console.log("Player prizes requested");
  }
  const response = await axiosInstance.get(
    `/players/${player}/prizes/?account=${account}`
  );
  return response.data;
};

const getLeaderBoards = async (account) => {
  if (DEBUG) {
    console.log("Leaderboards requested");
  }
  const response = await axiosInstance.get(`/leaderboards/?account=${account}`);
  return response.data;
};

const getLeaderBoard = async (account, boardId, teamId="") => {
  if (DEBUG) {
    console.log("Leaderboards requested");
  }
  const response = await axiosInstance.get(`/leaderboards/${boardId}/?account=${account}&team=${teamId}`);
  return response.data;
};

// Actions
const completeEvent = async (account, player, event, count = 1) => {
  try {
    const response = await axiosInstance.post(`/events/${event}/complete`, {
      account,
      player,
      count,
    });
    return response.status;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const redeemPrize = async (account, player, prize) => {
  const response = await axiosInstance.post(`/prizes/${prize}/claim`, {
    account,
    player,
  });
  return response.data;
};

export {
  getEvent,
  getEvents,
  completeEvent,
  getMission,
  getMissions,
  getPrize,
  getPrizes,
  getPlayerPrizes,
  redeemPrize,
  getPlayer,
  getPlayerLevels,
  getPlayers,
  getTeam,
  getTeams,
  getAchievements,
  getPlayerAchievements,
  getPlayerMission,
  getPlayerMissions,
  getLeaderBoards,
  getLeaderBoard
};
