import React from "react";
import { MessageElement } from "../../components/Elements/MessageElement";
import { MissionsList } from "../../components/Elements/MissionsList";
import { PlayersList } from "../../components/Elements/PlayersList";
import PrizesList from "../../components/Elements/PrizeList/PrizesList";
import { Leaderboard } from "../../components/Elements/Leaderboard/Leaderboard";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import "./styles.scss";

export const Dashboard = () => {
  const routeVariants = {
    initial: {
      opacity: 0,
      y: "50px",
    },
    final: {
      opacity: 1,
      y: "0px",
      transition: {
        duration: 0.3,
        delay: 0.2,
      },
    },
  };
  return (
    <motion.div
      variants={routeVariants}
      initial="initial"
      animate="final"
      className="page-content"
    >
      <div className="element-container-top">
        <MessageElement message="Welcome to the micro-rewards programme - completing simple challenges to earn points that you can then exchange for some great rewards" />
      </div>
      <div className="element-container-middle">
        <div className="dashboard--missions">
          <h2 className="section-title">
            <span>Challenges</span>
            &nbsp;
            <NavLink to="/challenges">View all</NavLink>
          </h2>
          <MissionsList usePagination={true} slimItems={true} />
        </div>
        <div className="dashboard--players">
          <h2 className="section-title">
            <span>Rankings</span>
            &nbsp;
            <NavLink to="/rankings">View all</NavLink>
          </h2>
          {/*<PlayersList usePagination={true} />*/}
          <Leaderboard displaySlim={true} usePagination={true} itemsOnPage={4} />
        </div>
        <div className="dashboard--shop">
          <h2 className="section-title">
            <span>Shop</span>
            &nbsp;
            <NavLink to="/shop">View all</NavLink>
          </h2>
          <PrizesList usePagination={true} />
        </div>
      </div>
    </motion.div>
  );
};
